import React from 'react'
import {useI18next, useTranslation} from 'gatsby-plugin-react-i18next'
import SEO from 'components/SEO'
import ContentContainer from 'components/ContentContainer'
import Text from 'components/Text'
import Layout from "../components/Layout";
import {graphql} from "gatsby";

const NotFoundPage = ({location, data}) => {
  const {t} = useTranslation()
  return (
    <Layout location={location}>
      <ContentContainer withMb withMt>
        <SEO title={t('pages.404.seoTitle')} description={t('pages.404.seoDescription')}/>
        <Text component="h1" size="titleMd" content={t('pages.404.title')}/>
        <Text content={t('pages.404.text')} size="md"/>
      </ContentContainer>
    </Layout>
  )
}

export default NotFoundPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
